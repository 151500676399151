
// #64d1e3
$color-bg: #48C3E2;

.home {
	padding: 0px;

	#box-search{
		position: absolute;
	    width: 100%;
	    top: 345px;
	    left: 0px;
		z-index: 2;
		#IBESearchBox{
			font-family: 'Quicksand', sans-serif !important;
			width: 500px !important;
			display: block;
			margin: 0px auto;
			min-height: 100px;
			position: relative;
		}
	}

}
.blog.home{
	#box-search{
		top: 150px;
		z-index: 999999;
	}
}
@media(max-width: 991px){
	.home{
		#box-search{
			position: relative;
			top: 10px;
			#IBESearchBox{
				width: 100% !important;
				display: block;
				margin: 0px auto;
			}
		}

	}
	.blog.home{
		#box-search{
			top: 10px;
		}
	}

}

@media(max-width: 768px){
	.home{
		#box-search{
			position: relative;
			top: 10px;
			#IBESearchBox{
				width: 100% !important;
				display: block;
				margin: 25px auto;
				.IBECommonWrapper{
					font-family: 'Quicksand', sans-serif !important;
				}
			}
			.IBESearchBoxHeader{
				background-color: $color-bg !important;
				display: none;
			}
			.IBESearchBoxDirection{
				margin-top: -20px;
				label{
					padding: 15px 30px !important;
					font-size: 15px !important;
					display: inline !important;
					color: #8d8b8b !important;
					background: #fff !important;
					margin-right: 0px !important;
					border: .5px solid #c4c4c4;
				}
				label.active{
					background: $color-bg !important;
					color: #fff !important;
				}
			}
			.IBEFormItem[data-type=text]{
				width: 100% !important;
			}
			.IBESearchBoxFrom{
				.IBEFormItem{
					margin-top: 30px !important;
				}
			}
			.IBEFormItem{
				margin-top: 15px !important;
			}

			.IBEFormItem[data-type=text] > input, .IBESearchAutoCompleteWrapper > input{
				border-left: solid 1px #ccc !important;
				padding-left: 20px !important;
				height: 56px !important;
			}
			.passenger{
				float: left !important;
				height: 56px !important;
			    margin-bottom: 0 !important;
			    position: relative !important;
			    width: 100%;
			    z-index: 2 !important;
			    border: solid 1px #ccc !important;
			    line-height: 35px !important;
			    /* border-left: 0 !important; */
			    border-radius: 0 3px 3px 0 !important;
			    text-indent: 10px !important;
			    font-size: 15px !important;
			    color: #333 !important;
			    padding: 1px;
			    margin-top: 15px;

			}
			.IBESearchBoxPerson{
				position: relative;
			}
			.form{
				display: none;
				position: absolute;
				background: #fff;
				top: 65px;
				padding: 10px;
				width: 100% !important;
				border: 1px solid #ccc;
				z-index: 5;
				.IBEFormItem{
					margin-top: 2px !important;
				}
				span.bt-close{
					position: absolute;
					right: 10px;
					color: #fff;
					width: 25px;
					height: 25px;
					top: 10px;
					border: 1px solid #64d1e3;
					background: $color-bg;
					font-size: 17px;
					text-align: center;
					border-radius: 50%;
				}
				span.bt-close:hover{
					cursor: pointer;
				}
			}
			.show{
				.form{
					display: block !important;
				}
			}
			.IBESearchBoxPerson .IBEFormItem{
				width: 100% !important;
			    float: left !important;
				span{
					background: $color-bg !important;
					margin-top: 0px !important;
					width: 100% !important;
					text-align: center;
					height: 56px !important;
					line-height: 54px !important;
					font-size: 20px !important;
				}
			}
		}
		.IBEMobile{
			#IBEAirPortSelectTitle{
	   			background: $color-bg !important;
	   		}
			#IBECloseAirPortSelect{
				background: $color-bg !important;
				border: 1px solid #fff;
			}
		}

	}
}
#IBEDatePickerTitle{
	background: $color-bg !important;
	
}
@media (min-width: 992px){
#box-search{
	#IBESearchBox{
		.IBECommonWrapper{
			font-family: 'Quicksand', sans-serif !important;
		}

		#IBEAirPortSearchTxt{
			height: 54px !important;
		}
		.IBESearchBoxHeader{
			background: transparent !important;
			font-size: 36px !important;
			color: #fff !important;
			position: absolute;
		    width: 200%;
		    text-align: center;
		    left: -50%;
			font-weight: normal !important;
			display: none;
		}
		.IBESearchBoxMain{
			margin-top: 50px;
			width: 206% !important;
			position: absolute;
			left: -53%;
			background: transparent !important;
			.IBERadioGroup{
				label{
					background-image: none !important;
					padding-left: 0px !important;
					color: #c2c2c6 !important;
					transition: .3s;
					font-size: 15px !important;
				}
				label:hover,label.active{
					color: #fff !important;
					text-decoration: underline;
				}
			}
			.IBESearchBoxFromTo{
				width: 48%;
	    		float: left;
				.IBESearchBoxFrom, .IBESearchBoxTo{
					width: 50%;
					display: inline-block;
					float: left;
				}
				.IBEFormItem{
					width: 100%;
				}
				.IBESearchBoxFrom{
					input{
						border-top-left-radius: 5px !important;
						border-bottom-left-radius: 5px !important;
					}
				}

			}
			.IBESearchBoxStartReturnDate{
				width: 27% !important;
				margin-left: -0px;
				.IBESearchBoxStartDate{
					margin: 0px !important;
					.IBEFormItem{
						width: 100% !important;
					}
				}
				.IBESearchBoxStartDate, .IBESearchBoxReturnDate{
					width: 50% !important;
				}
				.IBESearchBoxReturnDate{
					.IBEFormItem{
						margin-left: -1px;
					}
					input{
						border-left: 1px solid #ccc !important;
					}
				}
			}
			.IBESearchBoxPerson{
				width: 25% !important;
				margin-left: -1px;
				position: relative;
				.IBEFormItem[data-type=button]{
					width: 50% !important;
					span{
						border-top-right-radius: 5px !important;
    					border-bottom-right-radius: 5px !important;
					}
				}
			}
			.passenger{
				float: left;
				width: 50%;
				height: 56px;
				margin-top: 10px;
				outline: 0px none;
				padding: 11px 1px;
				padding-left: 10px;
				border: 0px ;
				line-height: 35px !important;
				font-size: 15px;
				:hover{
					cursor: pointer;
				}
			}
			.form{
				position: absolute;
				background: #fff;
				top: 80px;
				padding: 10px;
				display: none;
				z-index: 5;
				.IBEFormItem{
					margin-top: 0px !important;
					width: 100% !important;
					select{
						height: 35px !important;
						outline: 0px none !important;
						width: 100% !important;
					}
				}
				.triangle{
					position: relative;
					$w-triangle: 10px;
					$bg-triangle: #fff;
					span{
						position: absolute;
						top: -20px;
						width: 0px;
						height: 0px;
						border-left: $w-triangle solid transparent;
						border-right: $w-triangle solid transparent;
						border-bottom: $w-triangle solid $bg-triangle;
					}
				}
				span.bt-close{
					position: absolute;
					right: -10px;
					color: #fff;
					width: 25px;
					height: 25px;
					top: -10px;
					border: 1px solid $color-bg;
					background: $color-bg;
					font-size: 17px;
					text-align: center;
					border-radius: 50%;
				}
				span.bt-close:hover{
					cursor: pointer;
				}
			}
			.show{
				.passenger{
					outline: 0px none;
					padding-bottom: 7px !important;
					border-bottom: 4px solid $color-bg !important;
				}
				.form{
					display: block !important;
				}
			}
		}
		.IBEFormItem > input, .IBESearchAutoCompleteWrapper > input{
			height: 56px !important;
			padding: 10px 1px !important;
			padding-left: 20px !important;
			border-radius: 0px !important;
		}
		.IBEFormItem[data-type=select] > select{
			height: 56px !important;
			margin: 0px !important;
			width: 100% !important;
		}
		.IBESearchButton{
			height: 56px !important;
			margin: 0px !important;
			line-height: 55px !important;
			width: 100%;
		    line-height: 55px !important;
		    text-align: center;
			background-color: $color-bg !important;
		}
		input:focus{
			outline: 0px none;
			padding-bottom: 7px !important;
			border-bottom: 4px solid $color-bg !important;
		}
		#IBEDatePickerTitle{
			background:#65d2e3;
		}
	}
	//display right
	.right{
			position: relative;

			#IBESearchBox{
				width: 100% !important;
				display: block;
				margin: 25px auto;
				.IBESearchBoxMain{
					width: 100% !important;
					position: inherit !important;
				}
			}
			.IBESearchBoxHeader{
				background-color: $color-bg !important;
				display: none;
			}
			.IBESearchBoxDirection{
				margin-top: -20px;
				label{
					padding: 7px 15px !important;
					font-size: 15px !important;
					display: inline !important;
					color: #8d8b8b !important;
					background: #fff !important;
					margin-right: 0px !important;
				}
				label.active{
					background: $color-bg !important;
					color: #fff !important;
				}
			}
			.IBEFormItem[data-type=text]{
				width: 100% !important;
			}
			.IBEFormItem{
				margin-top: 15px !important;
			}
			.IBEFormItem[data-type=text] > input, .IBESearchAutoCompleteWrapper > input{
				border-left: solid 1px #ccc !important;
			}
			.passenger{
				float: left !important;
				margin-bottom: 0 !important;
				position: relative !important;
				width: 100%;
				z-index: 2 !important;
				border: solid 1px #ccc !important;
				height: 35px !important;
				line-height: 35px !important;
				/* border-left: 0 !important; */
				border-radius: 0 3px 3px 0 !important;
				text-indent: 10px !important;
				font-size: 15px !important;
				color: #333 !important;
				padding: 1px;
				margin-top: 15px;

			}
			.IBESearchBoxPerson{
				position: relative;
			}
			.form{
				display: none;
				position: absolute;
				background: #fff;
				top: 49px;
				padding: 10px;
				width: 100% !important;
				border: 1px solid #ccc;
				z-index: 5;
				.IBEFormItem{
					margin-top: 2px !important;
				}
			}
			.show{
				.form{
					display: block !important;
				}
			}
			.IBESearchBoxPerson .IBEFormItem{
				width: 100% !important;
				float: left !important;
				span{
					background: $color-bg !important;
					margin-top: 0px !important;
					width: 100% !important;
					text-align: center;
				}
			}

	}
}


}


#ui-datepicker-div.IBEMobileCalendar, .IBEMobile #IBESelectAirPortWrapper{
	z-index: 9999 !important;
}


#result-vmb{
	margin-top: 20px;
	font-family: 'Quicksand', sans-serif !important;
	@media(min-width: 1025px){
		.IBESRRight{
			width: 265px;
		}
		.IBESearchResult{
			width: 1025px;
		}
	}


	.SearchParamDomestic, .SearchParamGlobal{
		background: $color-bg !important;
	}
	.IBESelectFlight{
		background: $color-bg !important;
	}
	.IBESelectFlight i{
		background: $color-bg !important;
		color: #fff !important;
		border-left: 1px solid #fff !important;
	}
	.IBESRMain .ResultLeft .IBEBookingInfoBox > h1{
		background: $color-bg !important;
	}
	.NormalBox .Title{
		background: $color-bg !important;
	}
	.ButtonGreen{
		background: $color-bg !important;
		background-image: linear-gradient(to bottom, $color-bg, $color-bg);
	}
	.Box .BoxTitle{
		background: $color-bg !important;
	}
	#ckbListAirlines td label .IBEMinPrice{
		color: $color-bg !important;
	}
	#IBEProgressBarWrapper li.active i{
		color: #fff;
		background: $color-bg !important;
	}
	#IBEProgressBarWrapper li.active a{
		color: $color-bg !important;
	}
	#SearchParamDomesticDepart, #SearchParamDomesticReturn{
		position: relative;
		.button-backhome{
			position: absolute;
			right: 5px;
			top: 10px;
			border-radius: 50%;
			background: #fff;
			box-shadow: 0px 2px 2px #000;
			width: 30px;
			text-align: center;
			a{
				color: $color-bg;
				padding: 2px;
				font-size: 22px;
				.fa{
					font-size: 20px;
				}
			}
		}
	}
	.IBESRWrapper .IBESearchBoxMain{
		border-color: #d1d1d1;
	}
	.IBESRWrapper .IBESearchBoxHeader{
		background: $color-bg !important;
	}
	.IBESRWrapper .IBESearchButton,.IBESearchButton:hover{
		background-color: $color-bg !important;
		background-image: linear-gradient(to bottom, $color-bg, $color-bg) !important;
	}
	// .infocontent .PaymentMethodContent{
	// 	display: none !important;
	// }
	//ngoai nuoc
	.FareHeader{
		background-color: $color-bg !important;
	}
	.IBESlider .ui-slider-range{
		background: $color-bg !important;
	}
	.FareFlightContent .FilghtItem .FilghtInfo .FlightInfoDetails .FlightInfoDetailsContent .Depart .City,
	.FareFlightContent .FilghtItem .FilghtInfo .FlightInfoDetails .FlightInfoDetailsContent .Depart .Time .TimeDetail,
	.FareFlightContent .FilghtItem .FilghtInfo .FlightInfoDetails .FlightInfoDetailsContent .Arrival .City,
	.FareFlightContent .FilghtItem .FilghtInfo .FlightInfoDetails .FlightInfoDetailsContent .Arrival .Time .TimeDetail,
	.FareRuleView,
	.FareFlightContent .FilghtItem .FilghtInfo .FilghtInfoSummary .Point .City,
	.FlightItem .FlightDetail .Depart .City, .FlightItem .FlightDetail .Arrival .City{
		color: $color-bg !important;
	}

	.PageNumber{
		margin-left: 5px;
	}
	.GrayBox .Content.BorderTop ul{
		padding-left: 15px;
	}
	#IBEProgressBarWrapper li.active .arrow{
		background: rgba(0, 0, 0, 0) url("./img/arrowr.png") no-repeat scroll center center;
	}
	.IBESRWrapper .details{
		background-image: url("./img/detail-arrow.png");
	}
	label.ibe-label{
		background-image: url("./img/ibe-radio.png") !important;
	}
	.Ascending{
		background-image: url("./img/sort_arrows.png");
	}
	.Descending{
		background-image: url("./img/sort_arrows.png");
	}
	.CashPaymentContent .CashPaymentSelect .CashPaymentMethod{
		background: $color-bg;
		color: #fff;
	}
	.CashPaymentContent .CashPaymentSelect .CashPaymentMethod.selected{
		background: #fff;
		color: #000;
	}
	.PaymentBankSelect{
		position: relative;
	}
	.infoheader h3{
		font-weight: bold;
	}
}

.IBESRWrapper,.IBECommonWrapper{
	font-family: 'Quicksand', sans-serif !important;
}
.home #box-search .IBESearchBoxPerson .IBEFormItem span{
	font-size: 16px !important;
}

@media(min-width:1024px){
	#box-search.box-fixed{
		position: fixed !important;
		top: 0px !important;
		width: 100%;

		.IBESearchBoxHeader{
			display: none !important;
		}
		#IBESearchBox {
			.IBESearchBoxMain{
				margin-top: 0px !important;
				background: rgba(0,0,0,.5) !important;
			}
		}
	}

	.box-search-blog {
		#box-search{
			position: inherit !important;
			margin-top: 10px !important;
			margin-bottom: 10px !important;
			.IBESearchBoxHeader{
				display: none !important;
			}
			#IBESearchBox {
				width: 566px !important;;
				.IBESearchBoxMain{
					margin-top: 0px !important;
					background: rgba(0,0,0,.5) !important;
				}
			}
		}
		#box-search.box-fixed{
			position: fixed !important;
			top: 0px !important;
			width: 100%;
			margin-top: 0px !important;
			.IBESearchBoxHeader{
				display: none !important;
			}
			#IBESearchBox {
				.IBESearchBoxMain{
					margin-top: 0px !important;
					background: rgba(0,0,0,.5) !important;
				}
			}
		}
	}

}
.TotalPrice{
	color: #696969 !important;
}
.FareHeader .Price .TotalPrice{
	color: #ff3900 !important;
}
.GrandPrice, .TotalPrice .Price{
	color: #ff3900 !important;
}
#ui-datepicker-div{
	z-index: 99999999999 !important;
}
// @media(min-width:1024px){
// 	.home #box-search.box-fixed{
// 		#IBESearchBox{
// 			width: 80% !important;
// 			.IBESearchBoxMain{
// 				width: 100% !important;
// 				left: 0px;
// 				padding: 1px !important;
// 				.IBESearchBoxDirection{
// 					display: inline-block;
// 					width: 15%;
// 					float: left;
// 					.IBERadioGroup label{
// 						width: 48.5%;
// 						background: #fff;
// 						line-height: 20px !important;
// 						height: 45px !important;
// 						color: #555 !important;
// 						padding: 12px 0px !important;
// 						text-align: center;
// 						margin: 0px !important;
// 					}
// 					.IBERadioGroup label.active{
// 						background: #3ac2e2;
// 						text-decoration: none;
// 						color: #fff !important;
// 					}
// 				}
// 				.IBESearchBoxStartReturnDate{
// 					width: 25% !important;
// 				}
// 				.IBEFormItem{
// 					margin: 0px !important;
// 					width: 100%;
// 					input{
// 						height: 45px !important;
// 					}
// 					.fa{
// 						top: 13px !important;
// 						font-size: 19px !important;
// 					}
// 				}
// 				.IBESearchBoxFromTo{
// 					width: 35% !important;

// 				}
// 				.passenger{
// 					margin: 0px;
// 					height: 45px;
// 				}
// 				.IBESearchButton{
// 					height: 45px !important;
// 					line-height: 45px !important;
// 				}
// 			}
// 		}

// 	}
// }
.box-search-blog .btn-timve{
	display: none;
}
@media(max-width:768px){
	.box-search-blog{
		display: block !important;
		#box-search{
			display: none;
		}
	}
	.btn-timve{
		display: block !important;
		color:#fff;
		background-color: #0f82be;
		border:0px;
		outline: 0px;
		font-size: 18px;
		padding: 8px;
		position: fixed;
		top: 0px;
		left:0px;
		width:100%;
		z-index: 99;
		text-align: center;
		text-decoration: none;
	}
	.btn-timve:focus, .btn-timve:hover{
		text-decoration: none;
		color:#fff;
	}
}